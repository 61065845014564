import { useEffect, useState } from "react";
import { getTimeSlots, getVenues } from "../services/VenuesService";
import { useParams } from "react-router-dom";
const useVenue = () => {
  const [AllVenues, setAllVenues] = useState([]);
  const [venue, setVenue] = useState();
  const [loading2, setLoading] = useState(false);
  const [error2, setError] = useState(null);
  const params = useParams();
  const concept = (params.concept || "default").replace(/-/g, ' ').toLowerCase();
  
  

  const fetchTimeSlotsFromAPIWithId = async (id) => {
    try {
      const data = await getTimeSlots(id);
      return data;
    } catch (error) {
      setError(error);
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      await fetchConceptsFromAPI();
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []); 

  const fetchConceptsFromAPI = async () => {
    try {
      setLoading(true);
      const data = await getVenues();
        
      AddTimeSlots(data);
      setAllVenues(data);

      setLoading(false);
    } catch (error) {
      setError(error);
    }
  };
  const AddTimeSlots = async (AllVenues) => {
    try {
      setLoading(true);
      const venue = AllVenues.find((item) => item.name.toLowerCase() === concept);
      
        const timeSlots = await fetchTimeSlotsFromAPIWithId(venue.id);

        // Add the timeSlots result to the venue object
        const updatedVenue = {
          ...venue,
          timeSlotsObj: timeSlots,
        };


      setVenue(updatedVenue);

      setLoading(false);
    } catch (error) {
      setError(error);
    }
  };


  return {
    venue,
    AllVenues,
    loading2,
    error2,
  };
};

export default useVenue;
