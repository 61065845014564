import { LocationOn } from "@mui/icons-material";
import { Typography } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

function VenueCard({ item }) {
  const navigate = useNavigate();

  const params = useParams();
  const concept = (params.concept || "default")
    .replace(/-/g, " ")
    .toLowerCase();

  return (
    <div
      className="ParentConcepts_VenueCard"
      onClick={() => navigate(`/${params.source}/${item.name}`)}
    
    >
      <div className="Parent_overlay"></div>
      <img
        alt="venue"
        src={item.image}
        className="ParentConcepts_VenueImg"
        style={{}}
      />
      <img alt="logo" src={item.logo} className="Parent_Logo"  />

      
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "300",
          color: "#fff",
          textAlign: "center",
          zIndex: 100,
          justifySelf:'flex-end',
          alignSelf:'center',
          marginTop:'1rem'
        }}
        variant="h6"
        className="typography"
      >
        
        {item.type && item.type.split(" ").map((word,i)=>{
                return(word  +( i===item.type.split(" ").length-1? "" : " • "))    
            })}
      </Typography>
      <Typography
        sx={{
          fontSize: 14,
          fontWeight: "300",
          color: "#fff",
          textAlign: "center",
          zIndex: 100,
          justifySelf:'flex-end'
        }}
        variant="h6"
        className="typography"
      >
        <LocationOn sx={{ transform: "translateY(0.4rem)", color:'#E0BF9E' }} ></LocationOn>
        {item.location}
      </Typography>
    </div>
  );
}

export default VenueCard;
