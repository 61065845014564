import axios, { AxiosError } from "axios";
import { EndPoints } from "../constants/constants";
const CEQUENS_BASE_URL = "https://apis.cequens.com/mfa/v2/verifications";

// export const sendOtpViaSMS = async (recipientIdentifier) => {

//   const formattedRecipientIdentifier = `${recipientIdentifier}`;

//   try {
//     const requestBody = {
//       channel: "SMS",
//       recipientIdentifier: formattedRecipientIdentifier,
//       templateCode: "d9abe136-7dba-4e53-a1be-2a5bc53d7d3b",
//       otpCodeLength: 4,
//       otpCodeExpiry: 3,
//     };

//     const response = await axios.post(
//       EndPoints.sendOTP + `sendOTP?phoneNumber=${formattedRecipientIdentifier}`,
//     );

//     // Extract the verification ID from the response data
//     const instanceId = response.data.instanceId;
//     const checkCode = response.data.checkCode;

//     return {instanceId,checkCode}; // Return the verification ID
//   } catch (error) {
//     // Handle the error as before
//     if (axios.isAxiosError(error)) {
//       const axiosError = error ;
//       if (axiosError.response) {
//         console.error("Error response from server:", axiosError.response.data);
//         console.error("Status code:", axiosError.response.status);
//       } else {
//         console.error("Request failed:", axiosError.message);
//       }
//     } else {
//       console.error("An unexpected error occurred:", error);
//     }
//     throw error; // Re-throw the error to propagate it further if needed
//   }
// };

export const sendOtpViaChannel = async (recipientIdentifier, channel) => {
  const formattedRecipientIdentifier = `${recipientIdentifier}`;

  try {
    const requestBody = {
      channel,
      recipientIdentifier: formattedRecipientIdentifier,
      templateCode: "d9abe136-7dba-4e53-a1be-2a5bc53d7d3b",
      otpCodeLength: 4,
      otpCodeExpiry: 3,
    };

    const response = await axios.post(
      EndPoints.sendOTP + `sendOTP?phoneNumber=${formattedRecipientIdentifier}`,
      requestBody
    );
    // Extract the verification ID from the response data
    const instanceId = response.data.instanceId;
    const checkCode = response.data.checkCode;

    return { instanceId, checkCode }; // Return the verification ID
  } catch (error) {
    // Handle the error as before
    if (axios.isAxiosError(error)) {
      const axiosError = error;
      if (axiosError.response) {
        console.error("Error response from server:", axiosError.response.data);
        console.error("Status code:", axiosError.response.status);
      } else {
        console.error("Request failed:", axiosError.message);
      }
    } else {
      console.error("An unexpected error occurred:", error);
    }
    throw error; // Re-throw the error to propagate it further if needed
  }
};

export const verifyOtp = async (otpPasscode, checkCode) => {
  try {
    const requestBody = {
      otpPasscode,
      checkCode,
    };

    const response = await axios.post(
      EndPoints.sendOTP +
        `verifyOTP?otpPasscode=${otpPasscode}&checkCode=${checkCode}`
    );

    return response.data;
  } catch (error) {
    // Handle the error as before
    if (axios.isAxiosError(error)) {
      const axiosError = error;
      if (axiosError.response) {
        console.error("Error response from server:", axiosError.response.data);
        console.error("Status code:", axiosError.response.status);
      } else {
        console.error("Request failed:", axiosError.message);
      }
    } else {
      console.error("An unexpected error occurred:", error);
    }
    throw error; // Re-throw the error to propagate it further if needed
  }
};
