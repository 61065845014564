import AWS from 'aws-sdk';

export async function invokeLambda(functionName, requestBody) {
  let lambda = new AWS.Lambda();

  try {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    };

    let response = await lambda.invoke({
      FunctionName: `${functionName}-bakyprod`,
      Payload: JSON.stringify(options),
    }).promise();

    if (response.StatusCode !== 200) {
      throw new Error(`Lambda invocation returned status code: ${response.StatusCode}`);
    }

    if (!response.Payload) {
      throw new Error('No payload received from lambda function');
    }

    let payload = JSON.parse(response.Payload);
    if (payload.statusCode !== 200) {
        throw new Error(`Lambda function returned error with status code: ${payload.statusCode}`);
      }
    if (!payload.body) {
      throw new Error('No body in the payload');
    }

    let body = JSON.parse(payload.body);
    

    return body;
  } catch (error) {
    console.error('Error invoking lambda function:', error);
    // Depending on your use case, you might want to rethrow the error, return a default value, or handle it in another way.
    throw error; // Rethrow the error if you want the caller to handle it.
  }
}
