import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getParentConcepts } from "../services/GetParentConceptsService";
import { getVenues } from "../services/VenuesService";
const useParentConcepts = () => {
  const [parentConcepts, setParentConcepts] = useState([]);
  const [AllVenues, setAllVenues] = useState([]);
  const [parentConcept, setParentConcept] = useState();
  const [childConcepts, setChildConcepts] = useState([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const params = useParams();
  const concept = (params.concept || "default")
    .replace(/-/g, " ")
    .toLowerCase();

  const fetchData = async () => {
    try {
      setLoading(true);
      const parents = await fetchParentConceptsFromAPI();
      
      const concepts = await fetchConceptsFromAPI();
      const parent = parents.find(
        (item) => item.name.toLowerCase() === concept
      );

      setParentConcept(parent);
      const childConceptsObj = concepts.filter((item) =>
        parent.venues.includes(item.id)
      );

      setChildConcepts(childConceptsObj);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const fetchParentConceptsFromAPI = async () => {
    try {
      setLoading(true);
      const data = await getParentConcepts();
      
      setParentConcepts(data);
      
      setLoading(false);
      return data;
    } catch (error) {
      setError(error);
    }
  };
  const fetchConceptsFromAPI = async () => {
    try {
      setLoading(true);
      const data = await getVenues();

      setAllVenues(data);

      setLoading(false);
      return data;
    } catch (error) {
      setError(error);
    }
  };

  return {
    parentConcepts,
    AllVenues,
    parentConcept,
    childConcepts,
    loading,
    error,
  };
};

export default useParentConcepts;
