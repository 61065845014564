import './App.css';
import {Routes, Route} from 'react-router-dom';
import ReservationPage from './pages/ReservationPage';
import ParentConceptPage from './pages/ParentConceptPage';
import AWS from "aws-sdk"

import { COGNITO_IDENTITYPOOLID } from './constants/constants';

function App() {
  AWS.config.region = "us-east-2"
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({IdentityPoolId : COGNITO_IDENTITYPOOLID})
  
  return (
    <Routes>
      <Route path="/:source/:concept" element={<ReservationPage />} />
      <Route path="/parent/:source/:concept" element={<ParentConceptPage />} />
    </Routes>
  );
}

export default App;
