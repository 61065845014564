import React from "react";
import "./ParentConceptPage.css";
import useParentConcepts from "../hooks/useParentConcepts";
import { useParams } from "react-router-dom";
import { theme } from "../components/utils";
import {
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import useVenue from "../hooks/useAllVenues";
import VenueCard from "../components/VenueCard";
function ParentConceptPage() {
  const {
    loading,
    error,
    parentConcepts,
    AllVenues,
    parentConcept,
    childConcepts,
  } = useParentConcepts();

  const params = useParams();

  const originList = [
    "facebook",
    "instagram",
    "whatsapp",
    "website",
    "twitter",
    "link",
  ];
  const origin = params.source || "default";
  return error ? (
    <ThemeProvider theme={theme}>
      <div
        className="ParentConcepts"
        style={{
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{
            fontSize: 16,
            color: "#E0BF9E",
            textAlign: "center",
            zIndex: 100,
          }}
          variant="h6"
          className="typography"
        >
          <span style={{ color: "red" }}>Un expected error happened</span>
          <br></br>Sorry for any inconvenience.
        </Typography>
        <Button
          variant="contained"
          onClick={() => window.location.reload()}
          style={{ marginTop: "1rem" }}
        >
          retry
        </Button>
      </div>
    </ThemeProvider>
  ) : loading || !parentConcepts ? (
    <div
      className="ParentConcepts"
      style={{ alignItems: "center", justifyContent: "center" }}
    >
      <ThemeProvider theme={theme}>
        <CircularProgress color="primary" />
      </ThemeProvider>
    </div>
  ) : !originList.includes(origin.toLowerCase()) ? (
    <></>
  ) : (
    <div className="ParentConcepts">
      <div className="ParentConcepts_Container">
        <Typography
          sx={{
            fontSize: 16,
            color: "#ccc",
          }}
          variant="h6"
          className="typography"
        ></Typography>
        {childConcepts.map((item,index) => {
          return (
            <div className="venueCard_div" key={index}>
              <VenueCard item={item} />
            </div>
          );
        })}
      </div>
      <Typography
        sx={{
          fontSize: 14,
          color: "#fff",
          textAlign: "center",
          fontWeight: "300",
          marginBottom: "0.5rem",
        }}
        variant="h6"
        className="typography"
      >
        Powered by{" "}
        <a
          style={{ color: "#fff" }}
          href="https://anyware.software"
          target="_blank"
          rel="noreferrer"
        >
          Anyware.Software
        </a>
      </Typography>
    </div>
  );
}

export default ParentConceptPage;
