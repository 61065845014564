import styled from "@emotion/styled";
import { Button, TextField, createTheme } from "@mui/material";

export const theme = createTheme({
    palette: {
      primary: {
        light: "#E1C19E",
        main: "#E1C19E",
        dark: "#E1C19E",
        contrastText: "#fff",
      },
      secondary: {
        light: "#E1C19E",
        main: "#E1C19E",
        dark: "#E1C19E",
        contrastText: "#fff",
      },                        
      
    },
    root: {
        
      "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
        // Default transform is "translate(14px, 20px) scale(1)""
        // This lines up the label with the initial cursor position in the input
        // after changing its padding-left.
        transform: "translate(34px, 20px) scale(1);",
      },
      "&.Mui-focused .MuiInputLabel-outlined": {
        color: "purple",
      },
    },
    inputRoot: {
        
      color: "purple",
      // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
      '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
        // Default left padding is 6px
        paddingLeft: 26,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        color: "green",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        color: "red",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        color: "purple",
      },
    },
  });
  export const StyledButton = styled(Button)(({ theme }) => ({
    color: "#E1C19E",
    backgroundColor: "transparent",
    borderColor: "#E1C19E",
    "&:hover": {
      backgroundColor: "#E1C19E",
      borderColor: "#E1C19E",
      color: "#fff",
    },
    "&.Mui-disabled": {
      background: "#transparent",
      color: "#c0c0c0",
      borderColor: "#c0c0c0",
    },
  }));

  export const StyledInput = styled(TextField)({
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: 0,
        borderColor: "#E1C19E",
      },
      "&:hover fieldset": {
        borderColor: "#E1C19E",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#E1C19E",
      },
      "& input": {
        textAlign: "center",
        width: 60,
        color: "#fff",
      },
    },
  });

 export function formatDate(date) {
    const formattedDate = new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    // Reformat the date string to match the desired format
    const parts = formattedDate.split("/");
    return `${parts[2]}-${parts[0].padStart(2, "0")}-${parts[1].padStart(
      2,
      "0"
    )}`;
  }


  
  export function convertTo24HourFormat(time) {
    const [hour, minute] = time.split(/:|(?=[APap][Mm])/);
    const isPM = time.match(/[Pp][Mm]/);
    let hour24 = parseInt(hour, 10);

    if (isPM && hour24 !== 12) {
      hour24 += 12;
    } else if (!isPM && hour24 === 12) {
      hour24 = 0;
    }

    return `${hour24.toString().padStart(2, "0")}:${minute}`;
  }
