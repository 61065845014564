import axios from 'axios';
import { EndPoints, LambdaNames } from '../constants/constants';
import AWS from 'aws-sdk';
import { invokeLambda } from '../utils/utils';

export const getParentConcepts = async () => {
  try {
    const response = await invokeLambda(LambdaNames.ParentConcepts,{})


    
    return response;
  } catch (error) {
    // Handle errors more informatively, log, or re-throw if appropriate
    console.error('Error retrieving parent concepts:', error);
    // throw new Error('Failed to retrieve parent concepts'); // Or custom error message
  }
};
