import axios from 'axios';
import { EndPoints } from '../constants/constants';


// Function to create a booking
async function CreateBookingService(bookingData) {
  try {
    
    const response = await axios.post(EndPoints.createBooking, bookingData);
    return response;
  } catch (error) {
    console.error('Error creating booking:', error);
    throw error;
  }
}

export default CreateBookingService;
